<template>
  <div class="card">
    <div class="card-header header_options">
      <div class="card-title header_title"><span class="material-symbols-outlined">star</span><span class="m-left-10">Planos</span></div>
    </div>
    <TableComponent :fields="fields" :items="plans" :busy="loading">
      <template #cell(price_estimate)="slotProps">
        {{ slotProps.item.pivot.pricing.fee | currency }}
      </template>
      <template #cell(actions)="slotProps">
        <router-link
          :to="{ name: 'BillingPlan', params: { planId: slotProps.item.id } }"
          style="display: flex"
        >
          <span class="material-symbols-outlined title" style="margin-right: 10px;"> visibility </span> &nbsp;ver detalhes
        </router-link>
      </template>
    </TableComponent>
    <div class="row">
      <div class="col-12">
        <pagination :totalPages="pages" v-model="page" @change="fetch" />
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import TableComponent from '@/components/TableComponent.vue';
import Pagination from '@/components/PaginationComponent.vue';
import axios from '@/plugins/axios';

const webApi = axios();

export default {
  name: 'PlansCard',
  components: {
    TableComponent,
    Pagination,
  },
  data() {
    return {
      loading: true,
      page: 1,
      pages: 1,
      plans: [],
      fields: [
        'service',
        'name',
        'price_estimate',
        'actions',
      ],
    };
  },
  computed: {
    account() {
      return this.$store.state.account;
    },
  },
  created() {
    this.fetch();
  },
  methods: {
    fetch(page = this.page) {
      this.loading = true;
      this.page = page;
      webApi.get(`/plans?page=${this.page}`).then(
        (response) => {
          this.loading = false;
          this.plans = response.data.data;
          this.pages = response.data.last_page;
        },
        (error) => {
          this.content = error;
        },
      );
    },
  },
};
</script>

<style scoped lang="scss">
.card-header {
  margin-bottom: 15px;
}
.rebranding .card .opts_card i {
  font-size: 22px;
  transition: 0.5s;
  padding: 5px;
}

.rebranding .card .opts_card i:hover {
  background-color: var(--background-icon-hover);
  border-radius: 5px;
}
.rebranding .card {
  height: calc(100% - 1.875rem);
}
.card {
  box-shadow: none;
}
.card-header {
  border-bottom: #6B23DC3D 4px solid !important;
  padding: 0px;
  padding-bottom: 5px;
}
.card-header .card-title {
  color: #697077 !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  margin-top: 10px !important;
}
.header_title {
  display: flex;
  color: #697077 !important;
  font-size: 16px !important;
  font-weight: 400 !important;
}
.card-header input{
  height: 30px !important;
}
.card-header button {
  height: 30px !important;
}
.m-left-10 {
  margin-left: 10px;
}
</style>
