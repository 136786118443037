<template>
  <div class="card">
    <div class="card-header header_options">
      <div class="card-title header_title"><span class="material-symbols-outlined">request_quote</span><span class="m-left-10">Faturas</span></div>
    </div>
    <TableComponent :fields="fields" :items="invoices" :busy="loading">
      <template #cell(price)="slotProps">
        {{ slotProps.data | currency }}
      </template>
      <template #cell(actions)="slotProps">
        <a :href="slotProps.item.url">
          <span class="material-symbols-outlined">barcode_scanner</span>
        </a>
      </template>
    </TableComponent>
    <div class="row">
      <div class="col-12">
        <pagination :totalPages="pages" v-model="page" @change="fetch" />
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import TableComponent from '@/components/TableComponent.vue';
import Pagination from '@/components/PaginationComponent.vue';

export default {
  name: 'InvoicesCard',
  components: {
    TableComponent,
    Pagination,
  },
  data() {
    return {
      loading: true,
      invoices: [],
      page: 1,
      pages: 1,
      fields: [
        'id',
        'total',
        'status',
        'actions',
      ],
    };
  },
  computed: {
    account() {
      return this.$store.state.account;
    },
  },
  created() {
    this.fetch();
  },
  methods: {
    fetch(page = this.page) {
      this.page = page;
      this.loading = true;
      this.$store.dispatch('fetchInvoices', { page }).then((response) => {
        this.invoices = response.data.data;
        this.$emit('fetched', response.data.data);
        this.pages = response.data.last_page;
        this.loading = false;
      });
    },
  },
};
</script>

<style scoped lang="scss">
.card-header {
  margin-bottom: 15px;
}
.rebranding .card .opts_card i {
  font-size: 22px;
  transition: 0.5s;
  padding: 5px;
}

.rebranding .card .opts_card i:hover {
  background-color: var(--background-icon-hover);
  border-radius: 5px;
}
.rebranding .card {
  height: calc(100% - 1.875rem);
}
.card {
  box-shadow: none;
}
.card-header {
  border-bottom: #6B23DC3D 4px solid !important;
  padding: 0px;
  padding-bottom: 5px;
}
.card-header .card-title {
  color: #697077 !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  margin-top: 10px !important;
}
.header_title {
  display: flex;
  color: #697077 !important;
  font-size: 16px !important;
  font-weight: 400 !important;
}
.card-header input{
  height: 30px !important;
}
.card-header button {
  height: 30px !important;
}
.m-left-10 {
  margin-left: 10px;
}
</style>
