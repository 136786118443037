<template>
  <div class="content">
    <PageHeader :title="$t('invoices.header')" />
    <div class="page-content container-fluid">
      <div class="row">
        <div class="col-lg-3 col-sm-6" style="margin-bottom: 2rem">
          <div class="card-invoice-success card" style="margin-bottom: 2px;">
            <h4 class="card-title text-white">
              {{ account.balance | currency }}
            </h4>
            <p class="card-text text-white">
              {{ $t('home.basic-info.lbl-balance') }}
            </p>
            <router-link
              :to="{ name: 'MakeInvoice', params: {} }"
              class="btn-add-balance"
            >
              <span class="material-symbols-outlined"> add </span> &nbsp;Adicionar Crédito
            </router-link>
          </div>
          <div class="divider-credit"></div>
          <div class="divider-credit" style="width: 80%;"></div>
          <!--/Agentes Logados -->
          <!-- Fechamento de fatura -->
          <!-- <div class="card invoices-closing-card">
            <div class="container-fluid">
              <div class="row">
                <div
                  class="col-md-2"
                  style="display: flex; align-items: center"
                >
                  <div class="">
                    <span class="material-symbols-outlined icon_title">
                      receipt_long
                    </span>
                  </div>
                </div>
                <div class="col-md-10">
                  <div class="infos-desc">
                    <strong class="title">{{
                      $t('users.invoice-closing')
                    }}</strong>
                    <div class="w-100"></div>
                    <p class="mb-0 text-yup">12/10/2022</p>
                  </div>
                </div>
              </div>
            </div>
          </div> -->
          <!--/Fechamento de fatura -->
        </div>
        <div class="col-lg-3 col-sm-6">
          <!-- Agentes Logados -->
          <div class="card agentes-card" style="height: auto;">
            <div class="container-fluid">
              <div class="row">
                <div
                  class="col-md-2"
                  style="display: flex; align-items: center"
                >
                  <div class="">
                    <img
                      class="icon_title"
                      style="transform: scale(1.2)"
                      src="@/assets/icons/telemarketer.svg"
                      alt="Telemarketer Icon"
                    />
                  </div>
                </div>
                <div class="col-md-10">
                  <div class="infos-desc">
                    <strong class="title">{{ $t('users.logged-in') }}</strong>
                    <div class="w-100"></div>
                    <p class="mb-0 text-yup">25</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- Tipo de fatura -->
          <div class="card invoices-type-card" style="height: auto;">
            <div class="container-fluid">
              <div class="row">
                <div
                  class="col-md-2"
                  style="display: flex; align-items: center"
                >
                  <div class="">
                    <span class="material-icons icon_title"> star </span>
                  </div>
                </div>
                <div class="col-md-10">
                  <div class="infos-desc">
                    <strong class="title">{{
                      $t('users.invoice-type')
                    }}</strong>
                    <div class="w-100"></div>
                    <p class="mb-0 text-yup">Pré-pago</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!--/Tipo de fatura -->
        </div>
        <div class="col-lg-3 col-sm-12"></div>
        <div class="col-lg-3 col-sm-12">
          <div class="card invoices-card">
            <div class="container-fluid">
              <div class="row">
                <div class="col-sm-12 text-center">
                  <section class="text-center">
                    <svg
                      data-v-31a768e4=""
                      width="100"
                      height="100"
                      xmlns="http://www.w3.org/2000/svg"
                      class="circle-chart"
                      viewBox="-3 0 40 30"
                    >
                      <circle
                        class="circle-chart__background"
                        stroke="#efefef"
                        stroke-width="2"
                        fill="none"
                        cx="16.91549431"
                        cy="16.91549431"
                        r="15.91549431"
                      />
                      <circle
                        class="circle-chart__circle"
                        stroke="#752DE6"
                        stroke-width="2"
                        :stroke-dasharray="`${this.percent},100`"
                        stroke-linecap="round"
                        fill="none"
                        cx="16.91549431"
                        cy="16.91549431"
                        r="15.91549431"
                      />
                      <g class="circle-chart__info">
                        <text
                          class="circle-chart__percent font-w-100"
                          x="16.91549431"
                          y="17"
                          alignment-baseline="central"
                          text-anchor="middle"
                          font-size="8"
                        >
                          {{ openInvoices }}
                        </text>
                      </g>
                    </svg>
                  </section>
                  <strong class="title">{{$t('users.open-invoices')}}</strong>
                  <p>+ 0 total</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-6">
          <usage-records-card />
        </div>
        <div class="col-lg-6">
          <invoices-card />
        </div>
        <div class="col-lg-6">
          <plans-card />
        </div>
        <div class="col-lg-6">
          <div class="card">
            <div class="card-header header_options">
              <div class="card-title header_title"><span class="material-symbols-outlined">build_circle</span><span class="m-left-10">{{ $t('billing.prefs.header') }}</span></div>
            </div>
            <div>
              <b style="color: var(--gray-font-color-2); margin-bottom: 20px;display: block;">Endereço de cobrança</b>
              <div class="card-address">
                <span class="material-symbols-outlined place-icon">place</span>
                <p class="card-text">
                  <span style="color: #9ca7b0"
                    >Av São João, 473 Loja 03<br />Centro, Peruíbe - SP<br />11750-000</span
                  >
                </p>
                <img
                  class="img-right"
                  src="@/assets/place_icon.png"
                />
                <div style="clear:both;"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import moment from 'moment';
import PageHeader from '@/components/PageHeader.vue';
import UsageRecordsCard from '@/components/rebranding/cards/UsageRecordsCard.vue';
import InvoicesCard from '@/components/rebranding/cards/InvoicesCard.vue';
import PlansCard from '@/components/rebranding/cards/PlansCard.vue';

export default {
  name: 'Billing',
  components: {
    PageHeader,
    UsageRecordsCard,
    InvoicesCard,
    PlansCard,
  },
  data() {
    return {
      invoices: {},
      loaded: false,
      months: [],
      date: '',
      usage: [],
      percent: 10,
      openInvoices: 0,
      options: {
        timePicker: false,
        autoApply: true,
        className: 'form-control',
        maxSpan: {
          days: 31,
        },
        locale: {
          format: 'DD/MM/YYYY',
          daysOfWeek: ['Do', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sa'],
          monthNames: [
            'Jan',
            'Fev',
            'Mar',
            'Abr',
            'Mai',
            'Jun',
            'Jul',
            'Ago',
            'Set',
            'Otu',
            'Nov',
            'Dez',
          ],
        },
      },
      form: {
        date: {
          gte: moment().format('YYYY-MM-DD'),
          lte: moment().format('YYYY-MM-DD'),
        },
      },
    };
  },
  computed: {
    account() {
      return this.$store.state.account;
    },
  },
  created() {},
  methods: {
    // changeOpenInvoices(invoices) {
    //   invoices.forEach((invoice) => {
    //     console.log(invoice);
    //     if (invoice.status === 'pending') {
    //       this.openInvoices += 1;
    //     }
    //   });
    // },
  },
};
</script>

<style lang="scss" scoped>
.bg-balance {
  background-color: #38e08c;
}
.card-deck .card:not(.card-deck .card.bg-balance) {
  /* flex: 0 0 20%; */
  flex: 1;
}

.invoices-card p {
  color: #521e66 !important;
}

.invoices-closing-card p,
.invoices-closing-card span {
  color: #6eda2c !important;
}

.invoices-type-card p,
.invoices-type-card span {
  color: #ffc000 !important;
}

.rebranding .card {
  height: 100%;
}

.col-lg-6 {
  margin-bottom: 2rem;
}

.infos-wrapper .card-deck .container-fluid,
.infos-wrapper .card-deck .container-fluid .row {
  height: 100%;
}

.infos-desc {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

@media (min-width: 768px) {
  .card-deck .card {
    max-width: 23.333%;
  }
}

@media (max-width: 767.98px) {
  .card-deck {
    padding-right: 15px;
    padding-left: 15px;
  }
}

@media (min-width: 1360px) and (max-width: 1600px) {
  .infos-desc {
    padding-left: 10px;
  }
  .card-deck .card {
    flex: 1 !important;
  }
}
.card-invoice-success {
  background: #16CD0854;
  box-shadow: none;
  border: solid 1px #E4E7EC;
  border-radius: 8px;
  padding: 16px;
}
.card-invoice-success h4 {
  color: #000000 !important;
}
.card-invoice-success p {
  color: #000000 !important;
}
.agentes-card {
  border: #E4E7EC solid 1px;
  box-shadow: none;
  padding: 10px;
  border-radius: 8px;
}
.invoices-type-card {
  border: #E4E7EC solid 1px;
  box-shadow: none;
  padding: 10px;
  border-radius: 8px;
}
.card {
  margin-bottom: 10px;
}
.btn-add-balance {
  border: none;
}
.btn-add-balance {
  background: #D2F9CF;
  padding: 6px 24px 6px 24px;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.btn-add-balance {
  color: #16CD08;
}
.btn-add-balance span{
  color: #16CD08;
}
.invoices-card {
  border: #E4E7EC solid 1px;
  box-shadow: none;
  padding: 10px;
  border-radius: 8px;
  padding-bottom: 0px;
  height: auto !important;
}
.invoices-card strong {
  color: #000000 !important;
  font-weight: 400 !important;
  font-size: 14px !important;
}
.invoices-card p {
  color: #707070 !important;
}
.card-header {
  margin-bottom: 15px;
}
.rebranding .card .opts_card i {
  font-size: 22px;
  transition: 0.5s;
  padding: 5px;
}

.rebranding .card .opts_card i:hover {
  background-color: var(--background-icon-hover);
  border-radius: 5px;
}
.card {
  box-shadow: none;
}
.card-header {
  border-bottom: #6B23DC3D 4px solid !important;
  padding: 0px;
  padding-bottom: 5px;
}
.card-header .card-title {
  color: #697077 !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  margin-top: 10px !important;
}
.header_title {
  display: flex;
  color: #697077 !important;
  font-size: 16px !important;
  font-weight: 400 !important;
}
.card-header input{
  height: 30px !important;
}
.card-header button {
  height: 30px !important;
}
.m-left-10 {
  margin-left: 10px;
}
.card-address {
  border: #E4E7EC solid 1px;
  box-shadow: none;
  padding: 10px;
  border-radius: 8px;
  display: block;
}
.place-icon {
  margin-top: 20px;
  margin-right: 15px;
  margin-left: 10px;
}
.card-address span {
  float: left;
}
.card-address p {
  float: left;
  margin-bottom: 0;
}
.img-right {
  width: 70px;
  height: 70px;
  float: right;
}
.divider-credit {
  height: 4px;
  background: #B2EFAE;
  width: 90%;
  border-radius: 8px;
  margin: 0 auto;
  margin-bottom: 2px;
}
</style>
