<template>
  <div>
    <div class="card">
      <div class="card-header header_options">
        <div class="card-title header_title"><span class="material-symbols-outlined">currency_exchange</span><span class="m-left-10">Resumo de gastos</span></div>
      </div>
      <TableComponent :fields="fields" :items="usageRecords" :busy="loading">
        <template #cell(price)="slotProps">
          {{ slotProps.data | currency }}
        </template>
      </TableComponent>
      <div class="row">
        <div class="col-12">
          <pagination :totalPages="pages" v-model="page" @change="fetch" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import moment from 'moment';
import TableComponent from '@/components/TableComponent.vue';
import Pagination from '@/components/PaginationComponent.vue';

export default {
  name: 'UsageRecordsCard',
  components: {
    TableComponent,
    Pagination,
  },
  data() {
    return {
      loading: true,
      fields: [
        'description',
        'count',
        'price',
      ],
      form: {
        date: {
          gte: moment().format('YYYY-MM-DD'),
          lte: moment().format('YYYY-MM-DD 23:59:59'),
        },
      },
      usageRecords: [],
      page: 1,
      pages: 1,
      options: {
        timePicker: false,
        autoApply: true,
        className: 'form-control',
        maxSpan: {
          days: 31,
        },
        locale: {
          format: 'DD/MM/YYYY',
          daysOfWeek: ['Do', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sa'],
          monthNames: [
            'Jan',
            'Fev',
            'Mar',
            'Abr',
            'Mai',
            'Jun',
            'Jul',
            'Ago',
            'Set',
            'Otu',
            'Nov',
            'Dez',
          ],
        },
      },
    };
  },
  computed: {
    account() {
      return this.$store.state.account;
    },
  },
  created() {
    this.fetch();
  },
  methods: {
    applyFilter(filters) {
      this.form = filters;
      this.fetch(1);
    },
    fetch(page = this.page) {
      this.page = page;
      this.loading = true;
      this.$store
        .dispatch('fetchUsage', { page, ...this.form })
        .then((response) => {
          this.usageRecords = response.data;
          this.pages = response.last_page;
          this.loading = false;
        });
    },
    changeDate(date) {
      this.form.date.gte = moment(date[0], 'DD/MM/YYYY').format(
        'YYYY-MM-DD 00:00:00',
      );
      this.form.date.lte = moment(date[1], 'DD/MM/YYYY').format(
        'YYYY-MM-DD 23:59:59',
      );
      this.fetch();
    },
  },
};
</script>

<style scoped lang="scss">
.card-header {
  margin-bottom: 15px;
}
.rebranding .card .opts_card i {
  font-size: 22px;
  transition: 0.5s;
  padding: 5px;
}

.rebranding .card .opts_card i:hover {
  background-color: var(--background-icon-hover);
  border-radius: 5px;
}
.rebranding .card {
  height: calc(100% - 1.875rem);
}
.card {
  box-shadow: none;
}
.card-header {
  border-bottom: #6B23DC3D 4px solid !important;
  padding: 0px;
  padding-bottom: 5px;
}
.card-header .card-title {
  color: #697077 !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  margin-top: 10px !important;
}
.header_title {
  display: flex;
  color: #697077 !important;
  font-size: 16px !important;
  font-weight: 400 !important;
}
.card-header input{
  height: 30px !important;
}
.card-header button {
  height: 30px !important;
}
.m-left-10 {
  margin-left: 10px;
}
</style>
